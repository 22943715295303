<template>
  <div class="fobidden">
    <h1>sorry access forbidden</h1>
    <img src="../assets/image/403.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "../assets/scss/fobidden.scss";
</style>
